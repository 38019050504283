'use strict';

const getType = (form) => {
  const tagName = form.tagName;
  switch (tagName) {
    case 'INPUT':
      return $(form).attr('type');
    case 'TEXTAREA':
      return 'textarea';
    case 'SELECT':
      return 'select';
  }
};

export const formTool = {
  getType(form) {
    return getType(form);
  },
};

// フォームのname属性またはid属性からインデックスを取得する
// @example extractIndexFromAttr('employee_bank_account_0_bank_code') => '0'
// @example extractIndexFromAttr('employee[bank_account][0][bank_code]') => '0'
export const extractIndexFromAttr = (attr) => {
  const match = attr.match(/(\d+)/);
  return match ? match[1] : '';
};

// カスタム項目(整数 or 小数)において、フォームの値から単位とカンマを除去した文字列を返す
export const removeUnitAndComma = (value, unit, unitPosition) => {
  // 単位・カンマ(・空白)を削除
  // @example 単位が後で「km」の場合 -> '100.00 km' は単位が後であり、かつ終端から2文字目までが単位のため、後ろから3文字目まで削除
  // @example 単位が前で「$」の場合 -> '$ 100.00' は単位が前であり、かつ始端から1文字目までが単位のため、前から2文字目まで削除
  value = value.trim();
  if (unitPosition === 'prefix' && value.slice(0, unit.length + 1) === unit + ' ') {
    value = value.replace(unit, '').trim();
  } else if (unitPosition === 'suffix' && value.slice(-unit.length - 1) === ' ' + unit) {
    value = value.slice(0, -unit.length - 1);
  }
  // カンマが含まれる場合は必ず削除
  value = value.replace(/,/g, '');
  return value;
};

// カスタム項目(整数型)で使用するフォーカスアウト時の自動変換処理
// 受け取った値を整数に変換して返す
// 負の値許容・小数点以下は切り捨て
export const normalizeCustomNumberValue = (value) => {
  value = value.replace(/ー/g, '-') // 長音記号を半角ハイフンに変換
      .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0)) // 全角数字を半角数字に変換
      .replace(new RegExp(String.fromCharCode(0xFF0E), 'g'), String.fromCharCode(0x002E)) // 全角ピリオドを半角ピリオドに変換
      .replace(/(\d)\.+(.+)/, '$1') // 数字に挟まれた1つ目の小数点以降を削除 (ここでの切り捨てのために1つ前で半角ピリオドに変換している)
      .replace(/[^\d-]/g, '') // 負の符号以外の文字列を（カンマも）削除する
      .replace(/(?!^)-/g, '') // 不正な位置の負の符号を削除する
      .replace(/^0+(?!$)/, '') // 先頭の0を削除する
      .replace(/^-0+(\d)/, '-$1') // 先頭が-0でその後に数字が続く場合、先頭の0を削除する
      .replace(/^-0$/, '0') // -0を0に変換する
      .replace(/^-$/, '') // -だけの場合は空文字にする
      .trim(); // 前後空白除去
  return value;
};

// カスタム項目(小数型)で使用するフォーカスアウト時の自動変換処理
// 受け取った値を小数に変換して返す
// 負の値許容
export const normalizeCustomDecimalValue = (value) => {
  value = value.replace(/ー/g, '-') // 長音記号を半角ハイフンに変換
      .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0)) // 全角数字を半角数字に変換
      .replace(new RegExp(String.fromCharCode(0xFF0E), 'g'), String.fromCharCode(0x002E)) // 全角ピリオドを半角ピリオドに変換
      .replace(/[^-\d.]/g, '') // 負の符号と小数点以外の文字列を（カンマも）削除する
      .replace(/(?!^)-/g, ''); // 不正な位置の負の符号を削除する
  return value;
};

export const normalizeCustomDecimalValueFull = (value) => {
  value = normalizeCustomDecimalValue(value);
  // 整数部分と小数部分を分ける (もし2つ目の小数点が現れたら、それ以降は無視する)
  let integerPart = value.split('.')[0];
  let decimalPart = value.split('.')[1] || '';
  decimalPart = zeroPaddingForDecimalPart(decimalPart, 1);
  // 整数部を正規化
  integerPart = normalizeIntegerPart(integerPart);
  value = combineIntegerAndDecimalParts(integerPart, decimalPart);
  return value
};

// カスタム項目(小数型)で使用する小数部に対するゼロパディング処理
export const zeroPaddingForDecimalPart = (decimalPart, decimalPlaces) => {
  decimalPart = decimalPart.padEnd(decimalPlaces, '0');
  return decimalPart;
};

// カスタム項目(小数型)で使用する整数部に対する正規化処理
export const normalizeIntegerPart = (integerPart) => {
  integerPart = integerPart.replace(/^0+(?!$)/, '') // 先頭の0を削除する
      .replace(/^-0+(\d)/, '-$1') // 先頭が-0でその後に数字が続く場合、先頭の0を削除する
      .replace(/^-$/, ''); // -だけの場合は空文字にする
  return integerPart;
};

// カスタム項目(整数型・小数型)で使用するフォーカスアウト時のカンマ自動付与
// 3桁ごとにカンマを付与する
export const separateByCommas = (value) => {
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 3桁ごとにカンマを付与
  return value;
};

// カスタム項目(小数型)で使用するフォーカスアウト時の整数部・小数部を結合する処理
export const combineIntegerAndDecimalParts = (integerPart, decimalPart) => {
  let resultValue;
  if (integerPart === '') {
    // 整数部が空文字列になった場合は全体を空文字列にする
    resultValue = '';
  } else {
    // 結合する
    resultValue = integerPart + '.' + decimalPart;
  }
  // -0.000(マイナス符号がついており整数部と小数部が全て0)の場合に限り、マイナスを除去する
  if (/^-0.0+$/.test(resultValue)) {
    resultValue = resultValue.replace('-', '');
  }
  return resultValue;
};

// カスタム項目(整数型・小数型)で使用するフォーカスアウト時の単位自動付与
export const addUnit = (value, unit, unitPosition) => {
  if (unit === '') return value;

  if (value === '') {
    value = '';
  } else if (unitPosition === 'prefix') {
    value = unit + ' ' + value;
  } else if (unitPosition === 'suffix') {
    value = value + ' ' + unit;
  }
  return value;
};

export const formatNumber = (value, isCommaSeparated, unit, unitPosition) => {
  let v = normalizeCustomNumberValue(value);
  if (isCommaSeparated) {
    v = separateByCommas(v);
  }
  return addUnit(v, unit, unitPosition);
};
