import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectButton'];

  selectRoute(event) {
    console.log('selectRoute!');
    console.log(event.params.index);
    this.selectButtonTarget.removeAttribute('disabled');
    this.selectButtonTarget.value = event.params.index;
    this.selectButtonTarget.click();
  }
}
