import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['refreshButton', 'closeButton', 'content'];

  connect() {
    // 有効化すると、モーダルの外側をクリックした際に、モーダルを閉じるようになる
    // this.handleOutsideClick = this.handleOutsideClick.bind(this);
    // document.addEventListener('click', this.handleOutsideClick);
    document.addEventListener('keydown', this.preventSubmitWhenEnterPressed);
  }

  disconnect() {
    // 有効化すると、モーダルの外側をクリックした際に、モーダルを閉じるようになる
    // document.removeEventListener('click', this.handleOutsideClick);
    document.removeEventListener('keydown', this.preventSubmitWhenEnterPressed);
  }

  preventSubmitWhenEnterPressed(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  refresh() {
    this.refreshButtonTarget.removeAttribute('disabled');
    this.refreshButtonTarget.click();
  }

  handleOutsideClick(event) {
    // contentTarget 内のクリックを無視し、外側クリック時にイベントを発火
    if (this.hasContentTarget && !this.contentTarget.contains(event.target)) {
      this.closeButtonTarget.click();
    }
  }

  refreshWithDelay() {
    clearTimeout(this.refreshTimeout);

    // NAVITIME API の利用数削減と、UX のバランスをとる目的で、現状は 600ms 遅延させる
    this.refreshTimeout = setTimeout(() => {
      this.refresh();
    }, 600);
  }
}
