/* eslint-disable require-jsdoc */
import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    console.log('DatatableController#connect');
  }

  Search(event) {
    console.log('Search');
  }
}
